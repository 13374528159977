export default function throttle<T>(
  fn: (...args: any[]) => T,
  limit = 250
) {
  // this method doesnt schedule the call, it just prevents the call from happening more than once every `limit` ms
  let wait = false,
    result: T

  return function (/* ...args */) {
    if (!wait) {
      wait = true
      setTimeout(() => {
        wait = false
      }, limit)
      result = fn.apply(this, arguments)
    }

    return result
  }
}


type ArgumentTypes<T> = T extends (...args: infer U) => unknown ? U : never;

// this method schedules the call to happen at most once every second, but also ensures it is ran once a interval if called
export function VThrottle<T extends (...args: unknown[]) => unknown>(
  fn: T, 
  delayMs: number = 1000
): (...args: ArgumentTypes<T>) => void {
  let lastRun = 0;
  let timeout: NodeJS.Timeout | null = null;
  
  // Using ThisParameterType to properly handle 'this' context
  return function throttled(this: ThisParameterType<T>, ...args: ArgumentTypes<T>): void {
    const now = Date.now();
    
    // Clear any existing timeout
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    
    // If it's been less than delayMs since last run
    if (now - lastRun < delayMs) {
      // Schedule the next run at exactly delayMs after the last run
      timeout = setTimeout(() => {
        lastRun = Date.now();
        fn.apply(this, args);
      }, delayMs - (now - lastRun));
      return;
    }
    
    // If it's been more than delayMs, run immediately
    lastRun = now;
    fn.apply(this, args);
  };
}